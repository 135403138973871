<template>
  <div
    class="min-w-[248px] max-h-[calc(100vh_-_16px)] md:max-h-[32rem] max-w-[320px] w-full h-full pt-4 pb-2 flex flex-col gap-y-2 rounded border border-white/10 bg-brand-greyblack-300/80 backdrop-blur-xl"
  >
    <div class="flex-1 flex flex-col overflow-y-auto">
      <ButtonsMenuItem
        v-for="(channel, key) in navStore?.parsedChannels"
        :key="key"
        :path="{
          path: localePath('/live/' + channel.externalChannelId),
        }"
      >
        <template #prefix>
          <Avatar :background-image="channel.images.logo">
            <!-- <span class="text-xs"></span> -->
          </Avatar>
        </template>
        <span class="flex-1 flex items-center">
          {{ channel.name }}
        </span>
      </ButtonsMenuItem>
    </div>
    <div class="my-2 border-b border-white/10"></div>
    <div class="flex flex-col">
      <ButtonsMenuItem expanded path="/all-channels">
        {{ $t("All Channel Schedules") }}
      </ButtonsMenuItem>
    </div>
  </div>
</template>

<script setup>
import { useNavStore } from "@/stores/nav";
const navStore = useNavStore();
</script>
