<template>
  <NuxtLink
    :class="[
      expanded ? 'py-3' : 'py-[6px]',
      height > 40 ? 'items-start' : 'items-center',
    ]"
    :to="localePath(path)"
    class="px-6 outline-none flex gap-x-4 hover:bg-white/[15%] active:bg-white/30 sm:active:bg-inherit disabled:bg-white/20"
  >
    <slot name="prefix"> </slot>
    <div ref="title" class="flex-1 text-start text-white opacity-[0.87]">
      <slot></slot>
    </div>
    <slot name="suffix"></slot>
  </NuxtLink>
</template>

<script setup>
import { useElementSize } from "@vueuse/core";

const localePath = useLocalePath();
const { expanded, path } = defineProps({
  expanded: {
    type: Boolean,
    default: () => false,
  },
  path: [String, Object],
});

const title = ref();
const { height } = useElementSize(title);
</script>

<style lang="scss" scoped></style>
