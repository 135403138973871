import { defineStore } from "pinia";
import { getUserProfile } from "@/api/login";
import { useProfilesStore } from "@/stores/profiles";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: null,
    code: null,
    profiles: [],
    openSignInPopup: false,
  }),
  getters: {
    signedInUser() {
      const profile = this.profiles[0];
      if (!profile?.external_id) return null;
      try {
        return this.profiles[0];
      } catch (error) {
        return null;
      }
    },
  },
  actions: {
    setToken(token) {
      this.token = token;
    },
    setAuthInfo({ token, user_profiles }) {
      this.setToken(token);
      useProfilesStore().profiles = user_profiles;
    },
    setTvCode(code) {
      this.code = code;
    },
    getProfiles() {
      return getUserProfile().then((res) => {
        const { status, code, response } = res;
        if (status == 200 && code == "SUCCESS") {
          const validUser =
            Array.isArray(response) && Object.keys(response[0]).length > 0;
          return Promise.resolve(validUser ? response : false);
        } else {
          return Promise.resolve(false);
        }
      });
    },
    toggleSignInPopup(status = null) {
      this.openSignInPopup = status === null ? !this.openSignInPopup : status;
    },
    async signOut() {
      this.profiles = [];
      useProfilesStore().signOut();
    },
  },
  persist: [
    {
      key: "token",
      storage: persistedState.localStorage,
      paths: ["token"],
    },
    {
      key: "profiles",
      storage: persistedState.localStorage,
      paths: ["profiles"],
    },
    {
      key: "code",
      storage: persistedState.sessionStorage,
      paths: ["code"],
    },
    {
      key: "openSignInPopup",
      storage: persistedState.sessionStorage,
      paths: ["openSignInPopup"],
    },
  ],
});
