export default function () {
  const menuItems = ref([
    {
      name: "Search",
      path: "/search",
      icon: "ic:baseline-search",
      type: "both",
      device: "tv",
      requiresAuth: false,
    },
    {
      name: "Home",
      path: "/",
      icon: "clarity:house-line",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Home",
      path: "/kids",
      icon: "clarity:house-line",
      type: "kid",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Programs",
      path: "/kids/programs",
      icon: "IconVideocam",
      type: "kid",
      device: "both",
      requiresAuth: false,
    },

    {
      name: "Characters",
      path: "/kids/characters",
      icon: "IconCharacters",
      type: "kid",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Songs",
      path: "/kids/songs",
      icon: "IconSongs",
      type: "kid",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Majid TV",
      path: "/kids/majid-tv",
      icon: "ic:round-majid-tv",
      type: "kid",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Series",
      path: "/shows",
      icon: "IconVideocam",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Movies",
      path: "/movies",
      icon: "IconMovie",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Sports",
      path: "/sports",
      icon: "ic:baseline-sports-soccer",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "Channels",
      path: "/live",
      icon: "ic:round-live-tv",
      suffix: "IconChevronDown",
      type: "adult",
      device: "both",
      requiresAuth: false,
      disabled: true,
      children: [],
    },
    {
      name: "Radio",
      path: "/radio",
      icon: "ic:outline-radio",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "News",
      path: "/news",
      icon: "",
      type: "adult",
      device: "both",
      requiresAuth: false,
    },
    {
      name: "My List",
      path: "/kids/my-list",
      icon: "material-symbols:bookmarks-outline-rounded",
      type: "kid",
      device: "both",
      requiresAuth: true,
    },
  ]);

  const isBrowser = (menu) => {
    return menu.device != "tv";
  };
  const isKid = (menu) => {
    return menu.type != "adult";
  };
  const isAdult = (menu) => {
    return menu.type != "kid";
  };

  return {
    menuItems,
    isBrowser,
    isKid,
    isAdult,
  };
}
