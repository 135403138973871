<template>
  <NuxtLink class="px-3 gap-1 h-12 cursor-pointer" :to="to">
    <!-- text-white opacity-[0.87] hover:text-white opacity-50 active:text-white/40 -->
    <div
      class="disabled:cursor-not-allowed flex flex-col items-center drop-shadow-lg"
    >
      <span
        :class="[
          isSelected
            ? 'font-bold text-button-big'
            : 'font-normal text-body-big',
        ]"
        class="text-white opacity-[0.87] lg:hover:text-white opacity-50 sm:md:active:!text-white opacity-[0.87] active:text-white/40 tracking-wide bg-clip-text"
      >
        <slot></slot>
      </span>
      <svg
        v-if="isKid && isSelected"
        width="47"
        height="4"
        viewBox="0 0 47 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-brand-red w-full mt-1"
      >
        <path
          d="M0 1.15821C0 0.549206 0.5365 0.0810067 1.1395 0.166307C4.531 0.646307 14.8615 2.00001 23.5 2.00001C32.1385 2.00001 42.469 0.646307 45.8605 0.166307C46.4635 0.0810067 47 0.549206 47 1.15821C47 1.64401 46.6487 2.05781 46.1686 2.13131C43.2617 2.57621 33.2029 4.00001 23.5 4.00001C13.7971 4.00001 3.7383 2.57621 0.8314 2.13131C0.3513 2.05781 0 1.64401 0 1.15821Z"
          fill="#D02D2E"
        />
      </svg>

      <div
        v-else
        :class="[isSelected ? 'bg-brand-red' : 'bg-transparent']"
        class="lg:mt-2 mt-1 w-full h-0.5 rounded-full"
      ></div>
    </div>
  </NuxtLink>
</template>

<script setup>
const { isSelected } = defineProps({
  isSelected: { type: Boolean, default: () => false },
  disabled: { type: Boolean, default: () => false },
  readOnly: { type: Boolean, default: () => false },
  to: String,
});

const route = useRoute();
const isKid = computed(() => {
  return route.path.includes("kids");
});
</script>

<style lang="scss" scoped>
span {
  text-shadow: 0px 0px 20px #000;
}

.kidsIndicator {
  border: solid 5px theme("colors.white");
  border: solid 5px theme("colors.white");
  border-color: theme("colors.white") transparent transparent transparent;
  border-radius: 50%/100px 100px 0 0;
  transform: rotateX(180deg);
}
</style>
