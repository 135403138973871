<template>
  <div dir="ltr" class="flex items-center gap-x-1 divide-x-2">
    <NuxtLink
      v-for="l in locales"
      :class="[locale == l.code ? 'text-button-medium' : 'text-body-medium']"
      :key="l.code"
      @click="setLang(l.code)"
      :to="switchLocalePath(l.code)"
      class="px-2 text-white opacity-[0.87]"
      external
    >
      {{ l.label.toUpperCase() }}
    </NuxtLink>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const { setLang } = useSetLang();
const switchLocalePath = useSwitchLocalePath();

const locales = computed(() => {
  return [
    {
      code: "en",
      label: "EN",
    },
    {
      code: "ar",
      label: "العربية",
    },
  ];
});
</script>
