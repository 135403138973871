<template>
  <tippy
    :arrow="false"
    trigger="click"
    interactive
    :hide-on-click="true"
    @state="onStateChange"
    @show="onProfileShow"
    ref="profileRef"
  >
    <button
      :disabled="clickDisabled"
      :class="{ '!cursor-default': clickDisabled }"
      class="variant-secondary group h-10 w-10 md:h-12 md:w-12 flex justify-center items-center rounded-full outline-none backdrop-blur-sm duration-100 transition-all disabled:cursor-not-allowed"
      @click="isClicked = !isClicked"
    >
      <div v-if="!isClicked" class="relative w-full h-full">
        <!-- active profile may be null between sign in and whos watching -->
        <template v-if="sessionStore.activeProfile">
          <ProfileAvatar
            :key="sessionStore.activeProfile.property.avatar"
            :image="sessionStore.activeProfile.property.avatar"
            :profile-key="sessionStore.activeProfileKey"
            :is-kid="sessionStore.activeProfile.isKid"
            :color="sessionStore.activeProfile.color"
            class="w-full h-full"
            text-class="text-headline-small"
          ></ProfileAvatar>
        </template>
        <div
          v-if="sessionStore.activeProfile?.isKid"
          class="absolute z-10 -bottom-0 w-full h-4 flex items-center"
        >
          <Icon name="IconProfileKids" class="w-full h-4"></Icon>
        </div>
      </div>

      <div
        v-else
        class="w-full h-full flex justify-center items-center text-white text-button-big-bold rounded-full bg-white/30"
      >
        <Icon name="IconChevronUp" size="32"></Icon>
      </div>
    </button>

    <template #content>
      <div
        @click="onClickItem"
        class="min-w-[248px] max-h-[calc(100vh_-_16px)] max-w-max w-full h-full pt-4 pb-2 flex flex-col gap-y-2 rounded border border-white/10 bg-brand-greyblack-300/80 backdrop-blur-xl"
      >
        <h1
          class="text-label-medium px-6 text-white opacity-50"
          v-if="sessionStore.withoutSelfProfiles.length > 0"
        >
          {{ $t("Switch Profile to") }}
        </h1>
        <div class="flex flex-col">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <button
                v-for="(p, key) in sessionStore.withoutSelfProfiles"
                class="px-6 py-[6px] outline-none flex items-center gap-x-4 hover:bg-white/[15%] active:bg-white/30 sm:active:bg-inherit disabled:bg-white/20"
                @click="onSelectProfile(p)"
              >
                <div v-if="p.isKid" class="relative w-9 h-full">
                  <ProfileAvatar
                    :key="`${p.id}-${p.property.avatar}`"
                    :image="p.property.avatar"
                    :color="p.color"
                    :is-kid="p.isKid"
                    class="w-9 h-9"
                    text-class="text-body-big font-bold"
                  ></ProfileAvatar>
                  <div
                    class="absolute z-10 -bottom-0 w-full px-1 h-4"
                  >
                    <Icon name="IconProfileKids" class="w-full h-4"></Icon>
                  </div>
                </div>
                <ProfileAvatar
                  v-else
                  :key="`${p.id}-${p.property.avatar}`"
                  :image="p.property.avatar"
                  :profile-key="key"
                  :color="p.color"
                  class="w-9 h-9"
                  text-class="text-body-big font-bold"
                ></ProfileAvatar>
                <h3 class="flex-1 text-start text-body-big text-white opacity-[0.87]">
                  {{ p.name }}
                </h3>
              </button>
            </div>
          </div>
          <div
            class="my-2 border-b border-white/10"
            v-if="!sessionStore.withoutSelfProfiles.length == 0"
          ></div>
          <div class="flex flex-col">
            <ButtonsMenuItem path="/my-list">
              {{ $t("My List") }}
            </ButtonsMenuItem>
            <ButtonsMenuItem path="/profiles">
              {{ $t("Manage profiles") }}
            </ButtonsMenuItem>
            <ButtonsMenuItem
              path="/settings"
              v-if="sessionStore.hasMainAccount"
            >
              {{ $t("Account details") }}
            </ButtonsMenuItem>
          </div>
          <div class="my-2 border-b border-white/10"></div>
          <ButtonsMenuItem @click="onSignOut">
            <template #prefix>
              <Icon name="IconSignout" size="24" class="opacity-[.87]"></Icon>
            </template>
            {{ $t("Sign out") }}
          </ButtonsMenuItem>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
const { fetchProfileList } = useProfilesApi();
const route = useRoute();
const sessionStore = useSessionStore();
const localePath = useLocalePath();
const { setLang } = useSetLang();
const isClicked = ref(false);
const profileRef = ref();
const profile = computed(() => sessionStore.activeProfile || null);

// profile click should be disabled when it's on kids mode or profile
const clickDisabled = computed(() => {
  return profile.isKid || route.name.includes("kids");
});

const onStateChange = (e) => {
  isClicked.value = e.isShown;
};

const onProfileShow = async () => {
  sessionStore.profiles = await fetchProfileList();
};

const onSelectProfile = async (profile) => {
  await sessionStore.selectProfile(profile.external_id);
  await setLang(profile.property.language);
  /*
   * Selected user needs to refresh the page,
   * redirect to an empty page, and then return to the current page
   */
  // if (profile.isKid) {
  //   navigateTo({
  //     path: localePath("/kids"),
  //     query: { profile: profile.id },
  //     replace: true,
  //   });
  //   return;
  // }

  // navigateTo({
  //   path: localePath("/empty"),
  //   replace: true,
  // }).then(async () => {
  //   navigateTo({
  //     path: localePath("/"),
  //     query: { profile: profile.id },
  //     replace: true,
  //   });
  // });

  // TODO: bandaid fix for now to avoid parentnode issues
  window.location.reload();
  return;
};

const onSignOut = async () => {
  await navigateTo(localePath("/"));
  await sessionStore.signOut();
};

const onClickItem = () => {
  profileRef.value?.hide();
};

/**
 * Solve the problem of clicking on areas outside
 * the profile and not being able to close the pop-up box
 */
const bodyClickHandle = (e) => {
  if (
    profileRef.value?.state.isVisible &&
    !profileRef.value?.contentElem.contains(e.target) &&
    !profileRef.value?.elem.contains(e.target)
  ) {
    profileRef.value?.hide();
  }
};

onMounted(() => {
  document.addEventListener("click", bodyClickHandle, true);
});
onBeforeUnmount(() => {
  document.addEventListener("click", bodyClickHandle, true);
});
</script>

<style lang="scss" scoped></style>
